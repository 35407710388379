<template>
   <div>
      <!-- Breadcrumb start -->
      <section class="bradcrumb_sec">
         <div class="container-fluid custome_container">
            <div class="row justify-content-center">
               <div class="col-md-11">
                  <div class="bread_crumb_box mb_22">
                     <h2 class="heading_2 font_bold mb_23">Working from home <span class="text_primary">guide</span>
                     </h2>
                     <nav aria-label="breadcrumb">
                        <ol class="breadcrumb">
                           <li class="breadcrumb-item"><router-link class="navbar-brand m-0 p-0"
                                 :to="{ name: 'user_home' }">
                                 Home
                              </router-link></li>
                           <li class="breadcrumb-item active" aria-current="page">Working From Home</li>
                        </ol>
                     </nav>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Breadcrumb end -->
      <section :class="!company_code ? 'work_from_office how_else_ergo mb-0' : 'work_from_office how_else_ergo'" >
         <!-- <img src="../assets/images/work-from-office/first-section.png" alt="img" class="first_section"> -->
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-xxl-12">
                  <div class="row">
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'home_essentials' }">
                              <img src="../assets/images/home-essentials/home.png" alt="img" class="mb_66">
                              <!-- <div data-bs-toggle="modal" data-bs-target="#exampleModal">
                                 <img src="../assets/images/home-essentials/home-essential.png" alt="img" class="mb_66">
                              </div> -->

                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Home Essentials</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Create a viable place to work anywhere in your home.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>

                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'home_essentials_seating' }">
                              <img src="../assets/images/home-essentials/seating.png" alt="img" class="mb_66">
                              <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal1">
                                 <img src="../assets/images/home-essentials/seating1.png" alt="img" class="mb_66">
                              </a>                               -->
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Seating</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Learn how to sit pretty and have the best posture around.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'home_essentials_desktop' }">
                              <img src="../assets/images/home-essentials/desktop.png" alt="img" class="mb_66">
                              <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal2">
                                 <img src="../assets/images/home-essentials/desktop1.png" alt="img" class="mb_66">
                              </a> -->
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Desktop</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Enjoy ergo-friendly work surfaces in your home.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    10 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'home_essentials_couches' }">
                              <img src="../assets/images/home-essentials/couches.png" alt="img" class="mb_66">
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Couches</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Don’t be a couch slouch! Let us show you safe ways to work.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'home_essentials_household' }">
                              <img src="../assets/images/home-essentials/diy.png" alt="img" class="mb_66">
                              <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal3">
                                 <img src="../assets/images/home-essentials/household.png" alt="img" class="mb_66">
                              </a> -->
                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Household Ergo</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Household items can keep you in good ergo standing.

                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                     <div class="col-md-6 col-lg-4">
                        <div class="custome_card card2">
                           <router-link :to="{ name: 'home_stretches' }">
                              <img src="../assets/images/home-essentials/streches.png" alt="img" class="mb_66">
                              <!-- <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal4">
                                 <img src="../assets/images/home-essentials/home_stretchess.png" alt="img" class="mb_66">
                              </a> -->

                              <div class="card2_text_box">
                                 <h5 class="font_bold text-center font_size_26 mb_20 text_black">Home Stretches</h5>
                                 <p class="text-center font_size_18 mb_20 text_black">
                                    Take a break with these home stretches to feel great.
                                 </p>
                                 <p class="text_balticsea font_size_14 text_black">
                                    5 minute journey
                                 </p>
                              </div>
                           </router-link>
                        </div>
                     </div>
                  </div>
               </div>
            </div>

         </div>
      </section>
      <section class="take_an_ergonomic_parttwo mb_44" v-show="company_code">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-md-12 col-xl-10">
                  <div class="ergonomic-image pt_26 pb_26 ">
                     <div class="overlay">
                        <div class="row">
                           <div class="col-md-12 col-lg-7 col-xxl-6">
                              <div class="left-section pl_60 pr_60">
                                 <h6 class="sub_heading_1 font_bold mb_22 text-white">Take an ergonomic self-assessment
                                 </h6>
                                 <p class="text-white p_20 mb_54">Take a self-assessment today and see how ergo can help
                                    you feel great, enjoy more energy and supercharge your productivity. </p>
                                 <div class="mt_14">
                                    <router-link :to="{ name: 'self_assessment_signup' }"
                                       class="btn btn_secondary  font_size_16">Take a Self-Assessment
                                    </router-link>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- Video Modal start Here -->
      <!--<div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        
        <div class="text-end">
<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"><img
                           src="../assets/images/close_modal.png"></a>
                  </div>
                  <div class="video_elements">
                      <iframe src="https://player.vimeo.com/video/661824671?h=ebd4880672&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Put Your Pants On (and other WFH Tips)"></iframe>
                   
                  </div>
      </div>

    </div>
  </div>
</div>
<div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
         
        <div class="text-end">
<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"><img
                           src="../assets/images/close_modal.png"></a>
                  </div>
                  <div class="video_elements">
                      <iframe src="https://player.vimeo.com/video/395331378?h=1b4e1fe5dc&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Posture Perfect 2020"></iframe>
         </div>
      </div>

    </div>
  </div>
</div>
<div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
         
        <div class="text-end">
<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"><img
                           src="../assets/images/close_modal.png"></a>
                  </div>
                  <div class="video_elements">
                      <iframe src="https://player.vimeo.com/video/395333106?h=78605ec57d&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="Sit Stand Got You Down?"></iframe>
         </div>
      </div>

    </div>
  </div>
</div>
<div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal3" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
         
        <div class="text-end">
<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"><img
                           src="../assets/images/close_modal.png"></a>
                  </div>
                  <div class="video_elements">
                      <iframe src="https://player.vimeo.com/video/581287661?h=1244d7c6c8&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen style="position:absolute;top:0;left:0;width:100%;height:100%;" title="DIY Ergo"></iframe>
         </div>
      </div>

    </div>
  </div>
</div>
<div data-bs-backdrop="static" data-bs-keyboard="false" class="modal fade" id="exampleModal4" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-lg">
    <div class="modal-content">
      <div class="modal-body">
         
        <div class="text-end">
<a href="#" class="modal_close" data-bs-dismiss="modal" aria-label="Close"><img
                           src="../assets/images/close_modal.png"></a>
                  </div>
                  <div class="video_elements">
               <iframe
                        src="https://player.vimeo.com/video/327610298?h=88406977fd&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                        frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen
                        title="Neck Stretch"></iframe>
         </div>
      </div>

    </div>
  </div>
</div> -->
      <!-- Video Modal End Here -->
   </div>
</template>

<script>
export default {
   data() {
      return {
         company_code: true,
      }
   },
   name: "Work_from_home",
   mounted() {
      document.title = "PBErgo - "+this.$router.currentRoute.meta.page_title;
      let data = this.$store.getters["auth/authUser"];
      let company_data = data.client;
      if(company_data.self_assessment_button_status == 0){
         this.company_code = false;
      }
      if (
         localStorage.getItem("userToken") == null ||
         localStorage.getItem("employeeEmail") == null
      ) {
         this.$router.push({ name: "login" }).catch(() => true);
      }
   }
}
</script>